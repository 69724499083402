import React, {useState} from "react";
import {LanguageFlag} from "./components";

import "./resources/style.css";

const LanguageSelector: React.FC = () => {
    const [languageDialogOpen, setLanguageDialogOpen] = useState<boolean>(false);

    return (
        <section className="languages">
            <LanguageFlag onClick={() => setLanguageDialogOpen(true)}/>
        </section>
    )
}

export default LanguageSelector;