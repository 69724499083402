import React from 'react';
import ReactDOM from 'react-dom/client';
import './resources/index.css';
import App from "./components/Layout/App";

const rootElement = document.getElementById('root');
if(!rootElement) {throw new Error("The 'root' element does not exist in your HTML.");}

try {
    const root = ReactDOM.createRoot(rootElement);

    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}catch(error){console.error('Error rendering the React application:', error);}
