import React from "react";

const germanFlag = require("../resources/flags/germanFlag.png");

interface Props {
    onClick: () => void;
}

const LanguageFlag: React.FC<Props> = props => {
    return (
        <img className={"languageFlag"} src={germanFlag}/>
    )
}

export default LanguageFlag;