import React from "react";
import {useJsonHelper} from "../../../hooks";

const testImage = require("../resources/icon.jpg");

const BasicDesc: React.FC = () => {

    const {getTranslation} = useJsonHelper("overview");

    return(
        <section className={"floatRight"}>
            <h3>{getTranslation("BasicDesc.headline")}</h3>

            <img src={require("../resources/icon.jpg")} alt={"Profile Picture of the author"}/>

            <p>{getTranslation("BasicDesc.paragraph")}</p>
        </section>
    )
}

export default BasicDesc;