import React from "react";
import {useJsonHelper} from "../../hooks"

const Experience: React.FC = () => {

    const {getParagraphs} = useJsonHelper("experience");

    return(
        <div id={"experience"}>
            {getParagraphs()}
        </div>
    )
}

export default Experience;