import React, {useCallback, useMemo} from "react";

const useJsonHelper = (translation: string) => {

    const folderName = translation.charAt(0).toUpperCase() + translation.slice(1);

    const language = useMemo(() => {
        return "DE";
    }, []);

    const data: Record<string, any> = require(`../resources/translations/${language}/${translation}.json`);

    const getObject = useCallback((index: string): string|Record<string, any> => {
        const splitIndex: string[] = index.split(".");
        let value: string|Record<string, any> = data;

        splitIndex.forEach( key => {
            if(value.hasOwnProperty(key)){
                value = value[key];
            }else{
                throw new Error("Unable to find key: " + key);
            }
        });

        return value;
    }, [data]);

    const getTranslation = useCallback((index: string): string  => {
        try{
            const translation = getObject(index);
            if(typeof translation !== "string"){throw new Error("Unable to find translation for index: " + index)}
            return translation;
        }catch(error){
            console.error(error);
            return "Translation could not be found!";
        }
    }, [getObject]);

    const buildTableHeader = useCallback((tableHeaders: Record<string, any>): React.ReactNode => {
        return(
            <thead>
                {Object.keys(tableHeaders).map(row => (
                    <tr id={row}>
                        {Object.keys(tableHeaders[row]).map(col => (
                            <th scope={"col"}>{tableHeaders[row][col]}</th>
                        ))}
                    </tr>
                ))}
            </thead>
        )
    }, []);

    const buildTableBody = useCallback((tableBody: Record<string, any>): React.ReactNode => {
        return (
            <tbody>
                {Object.keys(tableBody).map(row => (
                    <tr key={row}>
                        {Object.keys(tableBody[row]).map(col => (
                            <td>{tableBody[row][col]}</td>
                        ))}
                    </tr>
                ))}
            </tbody>
        )
    }, [])

    const buildTable = useCallback((table: Record<string, any>): React.ReactNode => {
        return(
            <table>
                {buildTableHeader(table["head"])}
                {buildTableBody(table["body"])}
            </table>
        );
    }, []);

    const buildSections = useCallback((sections: Record<string, any>): React.ReactNode => {
        return(
            Object.keys(sections).map(section => (
                <section id={section["id"]}>
                    {sections[section].hasOwnProperty("headline") && <h4>{sections[section]["headline"]}</h4>}
                    {buildTable(sections[section]["table"])}
                </section>
            ))
        )
    }, []);

    const getParagraphs = useCallback((): React.ReactNode => {
        return Object.keys(data).map(p => {
                const paragraph = data[p];
                const className = paragraph.hasOwnProperty("className") ? paragraph["className"] : undefined;

                return <article id={paragraph["id"]} className={className}>
                    {paragraph.hasOwnProperty("headline") && <h3>{paragraph["headline"]}</h3>}
                    {paragraph.hasOwnProperty("img") && <img src={require(`../views/${folderName}/resources/${paragraph["img"]["src"]}`)}
                                                            alt={paragraph["img"]["alt"]}/>}
                    {paragraph.hasOwnProperty("content") && <p>{paragraph["content"]}</p>}
                    {paragraph.hasOwnProperty("table") && buildTable(paragraph["table"])}
                    {paragraph.hasOwnProperty("sections") && buildSections(paragraph["sections"])}
                </article>
            })
    }, []);

    return {
        buildTable,
        getTranslation,
        getParagraphs
    }
}

export default useJsonHelper;