import React from "react";
import {useJsonHelper} from "../../../hooks";

const testImage = require("../resources/icon.jpg");

const SecondParagraph: React.FC = () => {

    const {getTranslation} = useJsonHelper("overview");

    return(
        <section className="floatLeft">
            <h3>{getTranslation("BasicDesc.headline")}</h3>

            <img src={testImage} alt={"Profile Picture of the author"}/>
            <p>{getTranslation("BasicDesc.paragraph")}</p>
        </section>
    )
}

export default SecondParagraph;