import React from "react";
import {Route, Routes} from "react-router-dom";
import {
    Education,
    Experience,
    Overview
} from "../../views";

import "./resources/style.css";

const Main: React.FC = () => {
    return(
        <main>
            <Routes>
                <Route path={"/"} element={<Overview />}/>
                <Route path={"/education"} element={<Education />}/>
                <Route path={"/experience"} element={<Experience />}/>
            </Routes>
        </main>
    )
}

export default Main;