import React from "react";
import {useJsonHelper} from "../../hooks"

import "./resources/style.css";

const Education: React.FC = () => {

    const {getParagraphs} = useJsonHelper("education");

    return(
        <div id={"education"}>
            {getParagraphs()}
        </div>
    )
}

export default Education;