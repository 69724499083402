import React, {useMemo} from "react";
import {useNavigate} from "react-router-dom";
import {useJsonHelper} from "../../hooks";
import "./resources/style.css";

const NavBar: React.FC = () => {

    const navigate = useNavigate();
    const {getTranslation} = useJsonHelper("common");

    return(
        <nav>
            <h2 onClick={() => navigate("/education")}>{getTranslation("education")}</h2>
            <h1 onClick={() => navigate("/")}>Ammergeddon</h1>
            <h2 onClick={() => navigate("/experience")}>{getTranslation("experience")}</h2>
        </nav>
    )
}

export default NavBar;