import React from "react";
import {
    BasicDesc,
    SecondParagraph
} from "./components";

import {useJsonHelper} from "../../hooks";

import "./resources/style.css";

const Overview: React.FC = () => {

    const {getParagraphs} = useJsonHelper("overview");

    return(
        <div id={"overview"}>
            {getParagraphs()}
        </div>
    )
}

export default Overview;