import React, {useEffect} from "react";
import {BrowserRouter} from "react-router-dom";
import {NavBar} from "../NavBar";
import {LanguageSelector} from "../LanguageSelector";
import Main from "./Main";

const App = () => {

    useEffect(() => {
        const language = navigator.language;
        alert(language);
    }, []);

    return(
        <BrowserRouter>
            <LanguageSelector />
            <NavBar />
            <Main />
        </BrowserRouter>
    )
}

export default App;